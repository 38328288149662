<template>
  <CreateOrderForm store="order_retail" withProduct />
</template>

<script>
import CreateOrderForm from "../../components/CreateOrderForm.vue";
export default {
  components: { CreateOrderForm },
};
</script>
